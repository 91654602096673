import { makeApiCall } from './xhr';
import * as NetworkMock from './mocks/network_mock';

const BASE_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8080';
const API_URL = `${BASE_URL}/api/v1/app`;
const USE_MOCK = process.env.REACT_APP_MOCK === 'true';

export const initBvnRequest = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(NetworkMock.INIT_BVN_VALIDATION);
            }, 1000)
        );
    }

    return makeApiCall(`${API_URL}/requests/init`, 'post', payload);
}


export const validateBvnRequest = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(NetworkMock.VALIDATE_BVN_AUTH);
            }, 1000)
        );
    }

    return makeApiCall(`${API_URL}/requests/validate`, 'post', payload);
}

export const cancelBvnRequest = async (payload) => {
    if (USE_MOCK) {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(NetworkMock.VALIDATE_BVN_AUTH);
            }, 1000)
        );
    }

    return makeApiCall(`${API_URL}/requests/cancel`, 'post', payload);
}