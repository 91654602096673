import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'antd';

import success_img from '../assets/images/success.png';

const Success = () => {
    const client_data = useSelector((state) => state.clientData);

    useEffect(() => {
        const redirect_url = `${client_data.redirectUrl}?ref=${client_data.reference}&status=success`;
        setTimeout(() => {
            window.location.replace(redirect_url);
        }, 2000);
    }, []);

    return (
        <Row type='flex' justify='center' align='middle'
            style={{
                padding: 20,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '80vh'
            }}>
            <img style={{ height: 100 }} className='icon' alt='Success' src={success_img} />
            <div className='title'>Thank you!</div>
            <p className='description'>Redirecting you back to the app...</p>
        </Row>
    );
}

export default Success;