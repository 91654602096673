import React from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'antd';

import error_img from '../assets/images/error.png';

const Error = () => {
    const client_data = useSelector((state) => state.clientData);
    const error_message = `${client_data?.error_message || 'An unexpected error has occured. Please try again later.'}`;

    return (
        <Row type='flex' justify='center' align='middle'
            style={{
                padding: 20,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '80vh'
            }}>
            <img style={{ height: 100 }} className='icon' alt='Error' src={error_img} />
            <div className='title'>Oops!</div>
            <p className='description'>{error_message}</p>
        </Row>
    );
}

export default Error;