import { SET_CLIENT_DATA, CLEAR_CLIENT_DATA } from '../types/client_data_types';

const initialState = {};

const clientDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CLIENT_DATA: {
            return {
                ...state,
                ...action.client_data
            }
        }
        case CLEAR_CLIENT_DATA: {
            return {};
        }
        default: {
            return state;
        }
    }
};

export default clientDataReducer;