import { SET_CLIENT_DATA, CLEAR_CLIENT_DATA } from '../types/client_data_types';

export const setClientData = (client_data) => {
    return dispatch => {
        dispatch({
            type: SET_CLIENT_DATA,
            client_data
        })
    };
};

export const clearClientData = () => {
    return dispatch => {
        dispatch({
            type: CLEAR_CLIENT_DATA
        })
    };
};