import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ParamsResolver, Callback, Error, Success } from '../views/pages';

class AppNavigator extends Component {
    render = () => {
        return (
            <Routes>
                <Route exact path='/' name='Init' element={<ParamsResolver />} />
                <Route exact path='/callback' pathname='NibssCallback' element={<Callback />} />
                <Route exact path='/success' pathname='Success' element={<Success />} />
                <Route exact path='/error' pathname='Error' element={<Error />} />
            </Routes>
        );
    }
};

export default AppNavigator;
