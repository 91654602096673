import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import rootReducer from '../reducers/index';

const persistConfig = {
    key: 'moniepoint-igree',
    storage,
    whitelist: [ // Survives refresh
        'clientData'
    ],
    blacklist: [ // Does not survive refresh
    ],
    throttle: 0,
    transforms: process.env.NODE_ENV === 'productions' ? [
        encryptTransform({
            secretKey: process.env.REACT_APP_REDUX_KEY,
            onError: function (error) {
                console.error(error);
            },
        }),
    ] : [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));
let persistor = persistStore(store);

export { store, persistor };