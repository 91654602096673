import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppNavigator from './navigations/app_navigator';

import './styles/style.scss';

class App extends Component {
  render = () => {
    return (
      <BrowserRouter>
          <AppNavigator />
      </BrowserRouter>
    )
  }
}

export default App;
