import axios from 'axios';

import { store } from '../../redux/store';

const NETWORK_ERROR = {
    status: 'fail',
    message: 'It seems you have lost your internet connection. Kindly reconnect and try again',
    http_status: 502
};
const UNKNOWN_ERROR = {
    status: 'fail',
    message: 'An unexpected error has occured. Please try again later.',
    http_status: 500
};

const axiosConfig = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
}
let axiosInstance = axios.create(axiosConfig);

const logNetworkResult = (result) => {
    let { config, status, data } = result;
    logNetworkData(config, status, data);
}

const logNetworkError = (result) => {
    if (result.response) {
        let { response } = result;
        let { config, status, data } = response;
        logNetworkData(config, status, data);
    } else {
        console.log(JSON.stringify(result, null, 4));
    }
}

const logNetworkData = (config, status, data) => {
    console.log('\n====================>\n',
        JSON.stringify(config, null, 4),
        '\n====================>');
    console.log('\n<========== HTTP ' + status + ' ==========\n',
        JSON.stringify(data, null, 4),
        '\n<==============================');
}

const apiCall = async (url, method, body, additional_headers) => {
    const { client_key } = store.getState().clientData;
    let headers = {
        'Access-Key': client_key,
        ...additional_headers
    };

    switch (method) {
        case 'post':
        case 'put':
        case 'patch':
        case 'delete':
            return axiosInstance[method](url, body, { headers });
        case 'get':
        default:
            return axiosInstance[method](url, { headers });
    }
};

export const makeApiCall = async (url, method, body = {}, additional_headers = {}) => {
    return new Promise(function (resolve, reject) {
        apiCall(url, method, body, additional_headers).then(result => {
            logNetworkResult(result);
            resolve(result.data);
        }).catch(error => {
            // logNetworkError(error);

            if (error.response && error.response.data) {
                reject(error.response.data)
            } else if (error.code === 'ERR_NETWORK') {
                reject(NETWORK_ERROR)
            } else {
                // console.log('===== UNKNOWN ERROR =====\n', JSON.stringify(error, null, 4));
                reject(UNKNOWN_ERROR);
            }
        });
    });
}