import React, { Component } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

class Loader extends Component {
    render = () => {
        return (
            <Spin indicator={<LoadingOutlined className='loader' />} />
        )
    }
}

export default Loader;
