import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as NetworkAdapter from '../../adapters/network_adapter';
import { setClientData } from '../../redux/actions/client_data_actions';

import { SuspenseFallback } from '../../components/molecules';

const Callback = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const AUTH_ERROR = { code: '40300', message: 'An error occurred while authenticating BVN. Please try again later' };
    const client_data = useSelector((state) => state.clientData);

    useEffect(() => {
        if (!!client_data.code) {
            handleSuccessCallback(client_data.code)
        } else {
            // Failure Callback from NIBSS
            handleFailureCallback(client_data.error_description)
        }
    }, []);

    const handleSuccessCallback = (auth_code) => {
        NetworkAdapter.validateBvnRequest({
            reference: client_data.reference,
            authorizationCode: auth_code
        }).then((result) => {
            navigate('/success', { replace: true });
        }).catch((error) => {
            // If error code is for ALREADY_VALIDATED_REQUEST treat the same way as successful validation
            if (error.code === 'ERR012') {
                navigate('/success', { replace: true });
            } else {
                handleError(error);
            }
        });
    }

    const handleFailureCallback = (error_description) => {
        NetworkAdapter.cancelBvnRequest({
            reference: client_data.reference,
            errorDescription: error_description
        }).then(() => {
            handleError({ message: error_description });
        }).catch(() => {
            handleError({ message: error_description });
        });
    }

    const handleError = (error) => {
        if (client_data?.redirectUrl) {
            const redirect_url = `${client_data.redirectUrl}?ref=${client_data.reference}&status=fail&code=${error.code || AUTH_ERROR.code}&error_description=${error.message || AUTH_ERROR.message}`;
            window.location.replace(redirect_url);
        } else {
            dispatch(setClientData({ error_message: error?.message }))
            navigate('/error', { replace: true });
        }
    }

    return (
        <SuspenseFallback />
    );
}

export default Callback;